import React from "react";
import styled from "styled-components";

import { requireImage } from "Utils/assets";

const LogoImg = styled.img`
  width: ${props => props.size}px;
  z-index: 2;
  max-height: 50px;
`;

const Logo = ({ color, size=122 }) => {
    let logo = color === "black" ? requireImage("logo-ui-dark.svg") : requireImage("logo-ui.svg");

    return (
        <LogoImg 
          src={logo}
          alt={process.env.REACT_APP_VENDOR_NAME || process.env.VENDOR_NAME || "Platform.sh"}
          color="#1a192b"
          size={size}
          width={process.env.CUSTOM_LOGO_WIDTH}
        />

    );
};

export default Logo;
