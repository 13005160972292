import React from "react";
import styled from "styled-components";

const ButtonLayout = styled.div`
  border: none;
  background: #ffffff;
  border-radius: 2px;
  color: #4a495e;
  display: inline-flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  width: auto;
  text-transform: none;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  transition: 0.2s;
  transition-property: box-shadow, color;
  margin: 0 8px 0 0;
  padding: 12px 24px 12px 12px;
  text-decoration: none;

  img {
    margin-right: 16px;
    width: 24px;
    height: 21px;
  }

  &:hover {
    background: #ffffff;
    color: #145cc6;
    box-shadow: 0px 1px 4px rgba(26, 25, 43, 0.24);
  }

  @media only screen and (max-width: 940px) {
    font-size: 13px;
    margin: 0 24px 0 0;
  }

  @media only screen and (max-width: 500px) {
    font-size: 13px;
    margin: 0 8px 0 0;
  }

  @media only screen and (max-width: 350px) {
    font-size: 13px;
    margin-top: 8px;
  }
`;

const Button = ({ className, onClick, name, icon }) => (
  <ButtonLayout className={className} onClick={onClick}>
    <img alt={name} src={icon} /> {name}
  </ButtonLayout>
);

export default Button;
