import styled from "styled-components";

const Text = styled.div`
  margin: ${props => props.margin};
  &::first-letter {
    text-transform: uppercase;
  }
  font-weight: 600;
  font-size: 15px;
  line-height: 33px;

  max-width: 440px;

  @media only screen and (max-width: 940px) {
    font-size: 13px;
    margin: ${props => props.marginMobile || props.margin};
  }
`;

export default Text;
